import { useLooker } from "../../hooks/useLooker.hook";

const MarketingView = () => {
  const { url } = useLooker("2")
  return (
    <iframe
          title="Dashbaord General"
          src={url}
          allowFullScreen
        />
  )
}

export default MarketingView;
