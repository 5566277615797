export const HEADERS = [
  { header: "Correo", key: "EMAIL" },
  { header: "Resultado de Validación", key: "EMAIL_VALIDATION" },
  { header: "Razón de Validación", key: "VALIDATION_REASON" },
  { header: "Estado del contacto", key: "CONTACT_STATUS" },
  { header: "Última Conexión", key: "LAST_CONNECTION" },
  { header: "Número de Visitas", key: "VISIT_COUNT" },
];

export const EXTRA_HEADERS = [
  { header: "Nombre Completo", key: "FULL_NAME" },
  { header: "Teléfono", key: "PHONE" },
  { header: "Cumpleaños", key: "BIRTHDAY" },
  { header: "Nombre", key: "FIRST_NAME" },
  { header: "Apellido", key: "LAST_NAME" },
]