import { useEffect, useState } from "react";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import { Menu } from "primereact/menu";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import PrivateRoute from "../../routers/components/PrivateRoute.component";
import Routes from "../../routers/Routes.router.json";
import General from "./General.view";
import Marketing from "./Marketing.view";
import Stores from "./Stores.view";
import Activity from "./Activity.view";
import SocialNetworks from "./SocialNetworks.view";
import Contacts from "./Contacts.view";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import Icono from "../../design/assets/img/mnv/icons/icon-dashboard.svg";
import ChartSection from "./components/ChartSection.components";
import { getDashboards } from "../../service/Looker.service";
import ButtonDownloadCSV from "./components/ButtonDownloadCSV.component";
import NeoButtonSection from "../../design/design_components/neo/layout/NeoButtonSection.base";

export default function Home() {
  const history = useHistory();
  const location = useLocation();
  const [version, setVersion] = useState("");
  const [dashboards, setDashboards] = useState([]);

  useEffect(() => {
    (async () => {
      const { success, payload } = await getDashboards();
      if (success && payload?.dashboards?.length > 0) {
        setVersion("looker");
        setDashboards(payload?.dashboards ?? []);
      } else {
        setVersion("base");
      }
    })();
  }, []);

  const items = [
    {
      id: 1,
      label: "General",
      icon: "fa fa-chart-line",
      className: location.pathname === Routes.statistics.home ? "active" : "",
      command: () => {
        history.push({
          pathname: Routes.statistics.home,
        });
      },
      customComponent: General,
      customPath: Routes.statistics.home,
      enable: dashboards.some(d => d.DASHBOARD_ID === 1)
    },
    {
      id: 2,
      label: "Marketing",
      icon: "fa fa-bullhorn",
      className:
        location.pathname === Routes.statistics.marketing ? "active" : "",
      command: () => {
        history.push({
          pathname: Routes.statistics.marketing,
        });
      },
      customComponent: Marketing,
      customPath: Routes.statistics.marketing,
      enable: dashboards.some(d => d.DASHBOARD_ID === 2)
    },
    {
      id: 3,
      label: "Sucursales",
      icon: "fa fa-store",
      className: location.pathname === Routes.statistics.stores ? "active" : "",
      command: () => {
        history.push({
          pathname: Routes.statistics.stores,
        });
      },
      customComponent: Stores,
      customPath: Routes.statistics.stores,
      enable: dashboards.some(d => d.DASHBOARD_ID === 3)
    },
    {
      id: 4,
      label: "Actividad",
      icon: "fa fa-running",
      className:
        location.pathname === Routes.statistics.activity ? "active" : "",
      command: () => {
        history.push({
          pathname: Routes.statistics.activity,
        });
      },
      customComponent: Activity,
      customPath: Routes.statistics.activity,
      enable: dashboards.some(d => d.DASHBOARD_ID === 4)
    },
    {
      id: 5,
      label: "Redes Sociales",
      icon: "fa fa-users",
      className:
        location.pathname === Routes.statistics.socialNetworks ? "active" : "",
      command: () => {
        history.push({
          pathname: Routes.statistics.socialNetworks,
        });
      },
      customComponent: SocialNetworks,
      customPath: Routes.statistics.socialNetworks,
      enable: dashboards.some(d => d.DASHBOARD_ID === 5)
    },
    {
      id: 6,
      label: "Contactos",
      icon: "fa fa-address-book",
      className:
        location.pathname === Routes.statistics.contacts ? "active" : "",
      command: () => {
        history.push({
          pathname: Routes.statistics.contacts,
        });
      },
      customComponent: Contacts,
      customPath: Routes.statistics.contacts,
      enable: dashboards.some(d => d.DASHBOARD_ID === 6)
    },
    // {
    //   label: 'Encuestas',
    //   icon: 'fa fa-comment',
    //   className: location.pathname === Routes.statistics.surveys ? "active" : '',
    //   command: () => {
    //     history.push({
    //       pathname: Routes.statistics.surveys,
    //     });
    //   },
    //   customComponent: Surveys,
    //   customPath: Routes.statistics.surveys
    // },
  ];

  // const itemsFiltered = items.filter((item) =>
  //   dashboards.some((d) => d.DASHBOARD_ID === item.id)
  // );

  // useEffect(() => {
  //   const container = document.querySelector('.main-container');
  //   container.classList.add("fluid");
  //   return () => {
  //     container.classList.remove("fluid");
  //   }
  // }, [])

  if (version === "looker") {
    return (
      <div className="statistics-main-container">
        <div className="statistics-menu-container">
          <div>
            <NeoTitleMain title="Estadísticas" icon={Icono} />
            <Menu className="menu-statistics" model={items.filter(i => i.enable)} />
          </div>
          <div className="vertical-divider"></div>
        </div>

        <div className="looker-container">
          <Switch>
            {items.map((item) => (
              <PrivateRoute
                key={item.id}
                exact
                path={item.customPath}
                component={item.customComponent}
              />
            ))}
            <Route
              path="*"
              component={() => <Redirect to={Routes.statistics.home} />}
            />
          </Switch>
        </div>
      </div>
    );
  }

  if (version === "base") {
    return (
      <NeoGridContainer>
        <NeoTitleMain title="Dashboard" col="12" icon={Icono} />

        <NeoButtonSection
          align="right"
          col="12"
          md="6"
          extra="p-col-align-center p-flex-sm-row p-flex-column"
        >
          <ButtonDownloadCSV />
        </NeoButtonSection>

        <NeoColumn md="12">
          <ChartSection />
        </NeoColumn>
      </NeoGridContainer>
    );
  }

  return null;
}
