import React, { useEffect, useRef, useState } from 'react';
import { CONST } from '../../../consts/consts';
import NeoMultiSelectFilter from "../../../design/design_components/neo/table/NeoMultiSelectFilter.base";
import { getStoreList } from "../../../service/Store.service";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base";
import useToastContext from '../../../hooks/useToastContext.hook';
import Routes from "../../../routers/Routes.router.json";
import { useHistory } from "react-router-dom";
import NeoCellDataStatus from "../../../design/design_components/neo/data/NeoCellDataStatus.base";
import NeoButtonMain from '../../../design/design_components/neo/button/NeoButtonMain.base';

export default function StoresTable() {
  const NETWORK_STATUS = CONST.NETWORK_STATUS;
  const NETWORK_STATUS_COLLECTIONS = Object.entries(NETWORK_STATUS).map(
    (type) => type[1]
  );
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const dt = useRef(null);
  const toast = useToastContext();
  const history = useHistory();

  useEffect(() => {
    let mounted = true;

    setLoading(true);
    if (mounted) {
      getStoresData();
    }
    return () => {
      mounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getStoresData = async () => {
    let storeData = await getStoreList(true, { networkStatus: true }); // userId
    if (!storeData.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
      console.log(storeData);
    }

    setStores(storeData.result.storesList);
    setLoading(false);
  };

  const storeStatusBodyTemplate = (down) => {
    if (down) {
      return (
        <NeoCellDataStatus
          status={"alert"}
          label={CONST.NETWORK_STATUS.danger.label}
          icon="exclamation-circle"
        />
      );
    }
    return (
      <NeoCellDataStatus
        status={"on"}
        label={CONST.NETWORK_STATUS.ok.label}
        icon="check-circle"
      />
    );
  };

  const nameBodyTemplate = (name) => {
    return (
      <>
        <span className="p-column-title"> Nombre de sucursal</span>
        {name}
      </>
    );
  };

  const addressBodyTemplate = (address) => {
    let addressSeparated = address.split(",");
    return (
      <>
        <span className="p-column-title"> Dirección</span>
        {addressSeparated[0]},{addressSeparated[1]}
        <br />
        {addressSeparated[2]},{addressSeparated[3]},{addressSeparated[4]}
      </>
    );
  };

  const apCountBodyTemplate = (apCount) => {
    return (
      <>
        <span className="p-column-title">Cantidad de dispositivos </span>
        {apCount}
      </>
    );
  };

  const storeEstatusFieldElement = () => {
    return (
      <NeoMultiSelectFilter
        ref={dt}
        options={NETWORK_STATUS_COLLECTIONS}
        field={"DOWN"}
        placeholder="Todos"
        selectedItemsLabel="{0} tipos de estatus"
      />
    );
  };

  const nameFieldElement = () => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={"NAME"}
        placeholder={"Buscar por sucursal"}
        filterMatch={"contains"}
      />
    );
  };

  const addressFieldElement = () => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={"ADDRESS"}
        placeholder={"Buscar por dirección"}
        filterMatch={"contains"}
      />
    );
  };

  const apCountFieldElement = () => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={"AP_COUNT"}
        placeholder={"Cantidad mínima"}
        filterMatch={"gte"}
      />
    );
  };

  const heatmapFieldElement = (e) => {
    return (
      <NeoButtonMain
        ref={dt}
        icon="pi pi-map"
        onClick={(event) => {
          event.stopPropagation()
          goToStoreHeatmap(e.ID)
        }}
      />
    );
  }

  const goToStoreDetail = (storeId) => {
    history.push(`${Routes.stores.detailsWithOutParams}/${storeId}`);
  };

  const goToStoreHeatmap = (storeId) => {
    history.push(`${Routes.stores.heatmapWithoutParams}/${storeId}`)
  }

  return (
    <>
      <NeoTable
        ref={dt}
        emptyMessage="No hay sucursales registradas"
        value={stores}
        rows={10}
        selectionMode="single"
        dataKey="ID"
        paginator
        removableSort
        loading={loading}
        onRowSelect={(e) => goToStoreDetail(e.data.ID)}
      >
        <NeoTableColumn
          field="NAME"
          header="Nombre de sucursal"
          body={(e) => nameBodyTemplate(e.NAME)}
          sortable
          filter
          filterElement={nameFieldElement()}
          style={{ width: '30%' }}
        />
        <NeoTableColumn
          field="ADDRESS"
          header="Dirección"
          body={(e) => addressBodyTemplate(e.ADDRESS)}
          sortable
          filter
          filterElement={addressFieldElement()}
        />
        <NeoTableColumn
          field="AP_COUNT"
          header="Cantidad de dispositivos"
          body={(e) => apCountBodyTemplate(e.AP_COUNT)}
          sortable
          filter
          filterElement={apCountFieldElement()}
          style={{ width: '15%' }}
        />
        <NeoTableColumn
          field="DOWN"
          header="Estatus"
          body={(e) => storeStatusBodyTemplate(e.DOWN)}
          // className={(e) => storeStatusBodyTemplate(e.DOWN)}
          sortable
          filter
          filterElement={storeEstatusFieldElement()}
          style={{ width: '19%' }}
        />
        {/* <NeoTableColumn
          header="Mapas"
          body={(e) => heatmapFieldElement(e)}
        /> */}
      </NeoTable>
    </>
  );
}
