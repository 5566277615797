import backendRequestUtil from "../utils/backendRequest.util";

export async function getLooker(dashboard) {
    const { success, body, error } = await backendRequestUtil.get(`/api/v2/looker/dashboard/${dashboard}`)
    return success ? { success, payload: body } : { success, error }
} 

export async function getDashboards() {
    const { success, body, error } = await backendRequestUtil.get(`/api/v2/looker/dashboard`)
    return success ? { success, payload: body } : { success, error }
}
