
import { useLooker } from "../../hooks/useLooker.hook";

const GeneralView = () => {
  const { url } = useLooker("1")
  return (
    
        <iframe
          title="Dashbaord General"
          src={url}
          allowFullScreen
        />
      
  )
}

export default GeneralView;
