import React from 'react'
import NeoButtonMain from '../../../design/design_components/neo/button/NeoButtonMain.base'
import NeoButtonSection from '../../../design/design_components/neo/layout/NeoButtonSection.base'
import { CONST } from '../../../consts/consts';
import useToastContext from '../../../hooks/useToastContext.hook';
import { getContactsCSV } from '../../../service/Contacts.service';
import ExcelJs from "exceljs";
import { dateFormat } from '../../../utils/date.utils';
import { EXTRA_HEADERS, HEADERS } from '../../../consts/contacts.const';

export default function ButtonDownloadCSV() {
  const toast = useToastContext();

  async function crearExcel() {
    let dataContacts;
    dataContacts = await getContactsCSV();
    if (!dataContacts.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    } else {
      const workbook = new ExcelJs.Workbook();
      const worksheet = workbook.addWorksheet();

      const currentHeaders = [...HEADERS]
      EXTRA_HEADERS.forEach(extra => {
        if (dataContacts.result[1][0][extra.key] > 0) {
          currentHeaders.push(extra)
        }
      })
      worksheet.columns = currentHeaders;

      dataContacts.result[0].forEach(contact => {
        worksheet.addRow(contact);
      });
      await workbook.csv.writeBuffer().then((data) => {
        const blob = new Blob(["\ufeff" + data], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${dateFormat(Date.now())}_contactos.csv`);
        link.click();
      });
    }
  }

  return (
    <>
      <NeoButtonMain
        label="Descargar reporte de contactos"
        icon="pi pi-download"
        onClick={crearExcel}
        extra="p-button-primary"
      />
    </>
  );
}
