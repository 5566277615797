import React, { useEffect, useMemo, useRef, useState } from "react";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleSecond from "../../design/design_components/neo/title/NeoTitleSecond.base";

import Icono from "../../design/assets/img/wfi/icons/icon-branch-3.svg";

import NeoCard from "../../design/design_components/neo/panel/NeoCard.base";
import NeoDropdown from "../../design/design_components/neo/form/NeoDropdown.base";
import NeoCalendar from "../../design/design_components/neo/form/NeoCalendar.base";

import {
  getStoreFloors,
  getStoreHeatmap,
  getStoreHeatmapDuration,
  getStoreList,
  getStoreTrajectory
} from "../../service/Store.service"
import { CONST } from "../../consts/consts";
import useToastContext from "../../hooks/useToastContext.hook";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import moment from "moment-timezone";

import NeoTabMenu from "../../design/design_components/neo/menu/NeoTabMenu.base";
import StoreHeatmapTab from "./components/StoreHeatmapTab.component";
import InternalSpinner from "./components/InternalSpinner.component";

const colors = [
  { border: "hsla(118, 34%, 45%, 1)", background: "hsla(118, 34%, 45%, .5)" },
  { border: "hsla(48, 90%, 69%, 1)", background: "hsla(48, 90%, 69%, .5)" },
  { border: "hsla(30, 89%, 67%, 1)", background: "hsla(30, 89%, 67%, .5)" },
  { border: "hsla(357, 89%, 64%, 1)", background: "hsla(357, 89%, 64%, .5)" },
];

const zeroPad = (num, places) => String(num).padStart(places, "0");

const ComponentFloorPlan = ({floor, setState}) => {
  const imgRef = useRef()

  function handleImageLoad() {
    setState((prev) => ({
      ...prev,
      image: {
        naturalWidth: imgRef.current?.naturalWidth,
        naturalHeight: imgRef.current?.naturalHeight,
        clientWidth: imgRef.current?.clientWidth,
        clientHeight: imgRef.current?.clientHeight,
      },
      loading: {
        ...prev.loading,
        image: false
      }
    }));
  }

  return (
    <>
      {floor?.URL_PLAN && (
        <img
          ref={imgRef}
          key={1}
          alt="mapa"
          className="heatmap-map"
          src={floor.URL_PLAN}
          onLoad={handleImageLoad}
        />
      )}
    </>
  );
}

const formatDatetime = (date) => {
  return Intl.DateTimeFormat("es-MX", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  })
    .format(date)
    .replace(",", "");
};

export default function StoreHeatmap() {
  const now = new Date();
  const toast = useToastContext();
  const [state, setState] = useState({
    loading: {
      stores: true,
      floors: false,
      aps: false,
      trajectory: false,
      image: true,
    },
    index: 0,
    aps: [],
    trajectory: [],
    image: {
      naturalHeight: 0,
      naturalWidth: 0,
      clientHeight: 0,
      clientWidth: 0
    }
  });
  const [options, setOptions] = useState({
    stores: [],
    floors: [],
  });
  const [form, setForm] = useState({
    store: null,
    floor: null,
    from: moment(now).subtract(1, "hour").toDate(),
    to: moment(now).add(10, "minutes").toDate(),
  });
  const canvasRef = useRef()

  useEffect(() => {
    getStores();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current
    if(!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d")
    ctx.clearRect(0, 0, canvas.clientWidth, canvas.clientHeight)
  }, [state.index])

  useEffect(() => {
    if (!form.store || !form.floor) {
      return;
    }
    getAps(form.store?.ID, form.floor?.ID, { from: form.from, to: form.to });
  }, [form.store?.ID, form.floor?.ID, form.from, form.to]);

  useEffect(() => {
    if (!form.store || !form.floor) {
      return;
    }
    switch (state.index) {
      case 1:
        getApsDuration(form.store?.ID, { from: form.from, to: form.to })
        break;
      case 2:
        getTrajectory(form.store?.ID, form.floor?.ID, { from: form.from, to: form.to })
        break;
      case 0:
      default:
    }

  }, [form.floor?.ID, form.from, form.to, state.index])

  async function getStores() {
    try {
      const { result, success } = await getStoreList(true, { networkStatus: false }); // userId
      if (!success) {
        toast.setMessage(
          CONST.SEVERITY.ERROR,
          CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
          CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
        );
        return;
      }
      setOptions((prev) => ({ ...prev, stores: result.storesList }));
      setState((prev) => ({
        ...prev,
        loading: { ...prev.loading, stores: false },
      }));
    } catch (error) {
      console.error("Error cargando sucursales:", error);
    } finally {
      setState((prev) => ({
        ...prev,
        loading: { ...prev.loading, stores: false },
      }));
    }
  }

  async function getFloors(storeId) {
    setState((prev) => ({
      ...prev,
      loading: { ...prev.loading, floors: true },
    }));
    const { payload, success, error } = await getStoreFloors(storeId);
    if (!success || error) {
      setState((prev) => ({
        ...prev,
        loading: { ...prev.loading, floors: false },
      }));
      return;
    }
    const { floors } = payload;
    setOptions((prev) => ({ ...prev, floors }));
    setForm((prev) => ({ ...prev, floor: floors[0] }));
    setState((prev) => ({
      ...prev,
      loading: { ...prev.loading, floors: false },
    }));
  }

  async function getAps(storeId, floorId, { from, to }) {
    setState((prev) => ({ ...prev, loading: { ...prev.loading, aps: true } }));
    const { success, payload, error } = await getStoreHeatmap(
      storeId,
      floorId,
      formatDatetime(from),
      formatDatetime(to)
    );
    if (!success || error) {
      setState((prev) => ({
        ...prev,
        loading: { ...prev.loading, aps: false },
      }));
      return;
    }
    const { heatmap = [] } = payload;
    setState((prev) => ({
      ...prev,
      aps: heatmap,
      loading: { ...prev.loading, aps: false },
    }));
  }

  async function getApsDuration(storeId, { from, to }) {
    const { error, success, payload } = await getStoreHeatmapDuration(
      storeId,
      formatDatetime(from),
      formatDatetime(to)
    );
    if (error || !success) {
      return;
    }
    setState((prev) => ({
      ...prev,
      aps: prev.aps.map((ap) => ({
        ...ap,
        avgTime:
          payload?.rows.find((row) => row.ap_mac === ap.macAddress)?.avg_time ??
          0,
      })),
    }));
  }

  function handleChange(event) {
    const {
      value,
      target: { name },
    } = event;
    if (value) {
      setForm((prev) => ({ ...prev, [name]: value }));
    }
    return value;
  }

  function handleStoreChange(event) {
    const store = handleChange(event);
    if (!store) {
      return;
    }
    getFloors(store.ID);
  }

  function handleFloorChange(event) {
    const floor = handleChange(event);
    if (!floor) {
      return;
    }
  }

  function handleFromChange(event) {
    const from = handleChange(event);
    if (!from) {
      return;
    }
  }

  function handleToChange(event) {
    const to = handleChange(event);
    if (!to) {
      return;
    }
  }

  const apsWithStyles = (() => {
    const compareProperties = {
      0: { compareBy: "connections", zeroValue: 0 },
      1: { compareBy: "avgTime", zeroValue: 0 },
      2: { compareBy: "connections", zeroValue: 0 },
    };

    if (!compareProperties[state.index]) {
      return [];
    }

    const { compareBy, zeroValue } = compareProperties[state.index];

    const max = state.aps.reduce((max, ap) => Math.max(max, ap[compareBy]), 0);
    const apsWithColors = state.aps.map((ap) => {
      if (ap[compareBy] === zeroValue) {
        return {
          ...ap,
          border: "1px solid hsla(118, 34%, 45%, 1)",
          background: "hsla(118, 34%, 45%, .5)",
        };
      }
      const colorValue = (ap[compareBy] / max) * (colors.length - 1);
      const colorIndex = Math.round(colorValue);
      return {
        ...ap,
        border: colors[colorIndex]?.border,
        background: colors[colorIndex]?.background,
      };
    });
    return apsWithColors;
  })();
  const sortedAps = apsWithStyles.toSorted((a, b) => b.coordX - a.coordX);
  
  async function getTrajectory(storeId, floorId, {from, to}) {
    const {success, error, payload} = await getStoreTrajectory(
      storeId,
      floorId,
      formatDatetime(from),
      formatDatetime(to)
    );
    if (error || !success) {
      return;
    }
    const { rows } = payload
    setState(prev => ({...prev, trajectory: rows[0]?.ruta.split(',') ?? []}))
  }

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.lineWidth = 4;
    ctx.strokeStyle = "#ff0000";
    ctx.lineCap = "square";

    ctx.beginPath()

    if (state.trajectory.length === 0) {
      return;
    }

    const firstAp = 
      sortedAps.find(
        (ap) =>
          ap.macAddress?.toUpperCase() === state.trajectory[0]?.toUpperCase()
      ) ??
      sortedAps.find(
        (ap) =>
          ap.macAddress?.toUpperCase() === state.trajectory[1]?.toUpperCase()
      ) ??
      sortedAps.find(
        (ap) =>
          ap.macAddress?.toUpperCase() === state.trajectory[2]?.toUpperCase()
      );

    ctx.moveTo(
      (firstAp?.coordX * state.image.clientWidth) / state.image.naturalWidth,
      (firstAp?.coordY * state.image.clientHeight) / state.image.naturalHeight
    );

    let i = 1;
    ctx.font = "1rem Open Sans";
    for (const mac of state.trajectory) {
      const ap = sortedAps.find((ap) => ap.macAddress === mac);
      ctx.lineTo(
        (ap?.coordX * state.image.clientWidth) / state.image.naturalWidth,
        (ap?.coordY * state.image.clientHeight) / state.image.naturalHeight
      )
      // ctx.fillText(i++, (ap?.coordX * state.image.clientWidth) + 20 / state.image.naturalWidth, (ap?.coordY * state.image.clientHeight) / state.image.naturalHeight + 20);
    }

    ctx.stroke();
  }, [state.trajectory]);

  if (state.loading.stores) {
    return (
      <div style={{ height: "100%", display: "flex", justifyContent:"center", alignItems: "center" }}>
        <InternalSpinner />
      </div>
    );
  }

  return (
    <div style={{ marginLeft: "2rem" }}>
      <div style={{ height: "1rem" }} />

      <NeoGridContainer>
        <NeoDropdown
          label="Sucursal"
          value={form.store}
          onChange={handleStoreChange}
          options={options.stores}
          optionLabel="NAME"
          name="store"
        />
        <NeoDropdown
          label="Piso"
          value={form.floor}
          onChange={handleFloorChange}
          options={options.floors}
          optionLabel="NAME"
          name="floor"
        />
        <NeoCalendar
          col={2}
          label="Fecha de inicio"
          dateFormat="dd/mm/yy"
          value={form.from}
          onChange={handleFromChange}
          maxDate={form.to}
          showTime
          name="from"
        />
        <NeoCalendar
          col={2}
          label="Fecha de fin"
          dateFormat="dd/mm/yy"
          value={form.to}
          onChange={handleToChange}
          minDate={form.from}
          showTime
          name="to"
        />
      </NeoGridContainer>

      <div style={{ height: "1rem" }} />

      <NeoCard>
        {!form.floor ? (
          <p
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "0",
            }}
          >
            Selecciona una sucursal
          </p>
        ) : (
          <NeoTitleMain
            style={{ width: "100%", flexBasis: "100%" }}
            title={form.floor.NAME ?? "Seleccione una sucursal"}
          />
        )}

        {!form.floor ? null : (
          <>
            <NeoTabMenu
              style={{ width: "100%", marginBottom: "1rem" }}
              model={[
                { label: "Conexiones" },
                { label: "Duración" },
                { label: "Trayectoría" },
              ]}
              activeIndex={state.index}
              onTabChange={(e) => {
                setState((prev) => ({ ...prev, index: e.index }));
              }}
            />
            <div
              className="heatmap-container p-col p-col-12"
              style={{ minHeight: "40rem" }}
            >
              <ComponentFloorPlan floor={form.floor} setState={setState} />
              
              {!state.loading.image && (
                <canvas
                  ref={canvasRef}
                  height={state.image.clientHeight}
                  width={state.image.clientWidth}
                  style={{
                    position: "absolute",
                    top: 0,
                  }}
                />
              )}

              {!state.loading.image && (
                <div className="heatmap-heat-points">
                  {state.index === 0 &&
                    sortedAps.map((ap) => (
                      <StoreHeatmapTab
                        key={ap.id}
                        background={ap.background}
                        border={ap.border}
                        coordX={ap.coordX}
                        coordY={ap.coordY}
                        label={ap.label}
                        image={state.image}
                      >
                        {ap.connections} usuario
                        {ap.connections === 1 ? "" : "s"}
                      </StoreHeatmapTab>
                    ))}

                  {state.index === 1 &&
                    sortedAps.map((ap) => (
                      <StoreHeatmapTab
                        key={ap.id}
                        background={ap.background}
                        border={ap.border}
                        coordX={ap.coordX}
                        coordY={ap.coordY}
                        label={ap.label}
                        image={state.image}
                      >
                        {(() => {
                          const totalMinutes = Math.floor(
                            (ap.avgTime ?? 0) / 60
                          );

                          const seconds = Math.floor((ap.avgTime ?? 0) % 60);
                          const hours = Math.floor(totalMinutes / 60);
                          const minutes = totalMinutes % 60;

                          const hoursFormatted = zeroPad(hours, 2);
                          const minutesFormatted = zeroPad(minutes, 2);
                          const secondsFormatted = zeroPad(seconds, 2);

                          return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
                        })()}
                      </StoreHeatmapTab>
                    ))}

                  {state.index === 2 &&
                    sortedAps.map((ap) => (
                      <StoreHeatmapTab
                        key={ap.id}
                        background={ap.background}
                        border={ap.border}
                        coordX={ap.coordX}
                        coordY={ap.coordY}
                        label={ap.label}
                        image={state.image}
                      >
                        {ap.connections} usuario
                        {ap.connections === 1 ? "" : "s"}
                      </StoreHeatmapTab>
                    ))}
                </div>
              )}
            </div>
            <div className="heatmap-bar-container">
              <div className="heatmap-bar" />
              <div className="heatmap-bar__symbols">
                <p>-</p>
                <p>+</p>
              </div>
            </div>
          </>
        )}
      </NeoCard>
    </div>
  );
}
